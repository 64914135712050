<template>
    <div class="page">
      <div class="sidebar">
        <div class="img">
          <img width="220px" src="@/assets/gis/caso.png" alt="" />
        </div>
        <div class="list">
            <div style="padding: 20px;color: #fff;">
              <div>当前账号:{{ userInfo.email }}</div>
            </div>
            <div class="query">
                <img src="@/assets/gis/add.png" alt="">
                <span>报名信息管理</span>
            </div>
            <router-link to="/signList">
                <div class="real">
                  <span v-if="userInfo.type == 9">
                    报名审核
                  </span>
                  <span v-else>
                    报名列表(论文上报)
                  </span>
                </div>
            </router-link>
            <router-link to="/review" v-if="userInfo.type == 9 || userInfo.type === 91">
                <div class="real">
                    <span>专家评审</span>
                </div>
            </router-link>
            <router-link to="/info">
                <div class="history">
                    <span>参加比赛</span>
                </div>
            </router-link>
            <template v-if="showReport">
                <div class="query">
                    <img src="@/assets/gis/add.png" alt="">
                    <span>气象数据上报</span>
                </div>
                <router-link to="/uploadData">
                    <div class="history">
                        <span>查看每日数据</span>
                    </div>
                </router-link>
            </template>
        </div>
      </div>
      <div class="content-container">
        <router-view></router-view>
      </div>
    </div>
  </template>
  <script>
  import $cookies from "vue-cookies";
  import { $activity } from '@/services'
  export default {
    name: "signDetile",
    data () {
      return {
        userInfo: null,
        showReport:true
      };
    },
    created () {
      this.userInfo = $cookies.get("userInfo")
      this.getSignInfo()
    },
    methods: {
        // 获取报名信息
        async getSignInfo() {
          let params = {}
          params.pageNum = 1
          params.pageSize = 999
          params.keyword = ''
          params.userId = this.userInfo.id
          let res = await $activity.signList(params)
          let data = res.data.records
          let index = data.findIndex(v => v.userId === this.userInfo.id)
          if (index !== -1) {
            this.showReport = true
          } else {
            this.showReport = false
          } 
          console.log()
        },
    },
  };
  </script>
  <style lang="less" scoped>
  .page {
    width: 100%;
    height: 100vh;
    display: flex;
    align-items: flex-start;
    .sidebar {
      flex-shrink: 0;
      padding: 65px 20px 20px 20px;
      width: 320px;
      min-height: 100vh;
      background: linear-gradient(0deg, #31b2a0 0%, #00a8d8 100%);
      display: flex;
      flex-direction: column;
      .img {
        width: 280px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .list {
        user-select: none;
        flex: 1;
        margin-top: 64px;
        .router-link-active {
          color: white;
          background-color: #076fcc;
          .real {
            background: #0763a5;
            span {
              color: #ffffff;
            }
          }
          .history {
            background: #0763a5;
            span {
              color: #ffffff;
            }
          }
        }
        .query {
          margin-top: 10px;
          padding-left: 20px;
          width: 280px;
          height: 60px;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 10px 10px 0px 0px;
          img {
            width: 16px;
            height: 16px;
            line-height: 60px;
          }
          span {
            margin-left: 10px;
            font-size: 20px;
            font-family: MicrosoftYaHei-, MicrosoftYaHei;
            font-weight: normal;
            color: #00325f;
            line-height: 60px;
          }
        }
        .real {
          margin-top: 2px;
          padding-left: 36px;
          width: 280px;
          height: 60px;
          background: rgba(255, 255, 255, 0.8);
          span {
            margin-left: 10px;
            font-size: 20px;
            font-family: KaiTi-, KaiTi;
            font-weight: normal;
            color: #00325f;
            line-height: 60px;
          }
          &:hover {
            span {
              color: #ffffff;
            }
            background: #0763a5;
          }
        }
        .history {
          margin-top: 2px;
          padding-left: 36px;
          width: 280px;
          height: 60px;
          background: rgba(255, 255, 255, 0.8);
          border-radius: 0px 0px 10px 10px;
          span {
            margin-left: 10px;
            font-size: 20px;
            font-family: KaiTi-, KaiTi;
            font-weight: normal;
            color: #00325f;
            line-height: 60px;
          }
          &:hover {
            span {
              color: #ffffff;
            }
            background: #0763a5;
          }
        }
      }
      .links {
        width: 280px;
        height: 140px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(95, 242, 254, 0.15);
        border-radius: 1em;
        .item {
          width: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
          img {
            width: 94px;
          }
        }
      }
    }
    .content-container {
      height: 100%;
      flex: 1;
    }
    .nav {
      margin: 20px 20px 0 0;
      width: 60px;
      padding: 8px;
      background: #ffffff;
      border-radius: 10px;
      display: flex;
      flex-direction: column;
      .item {
        cursor: pointer;
        width: 44px;
        height: 48px;
        border-radius: 10px;
        font-size: 16px;
        color: #fff;
        text-align: center;
        line-height: 1.2;
        display: flex;
        align-items: center;
        justify-content: center;
        &:not(:first-child) {
          margin-top: 6px;
        }
        &:first-child {
          background: linear-gradient(0deg, #fa8cf4 0%, #0679e5 100%);
        }
        &:nth-child(2) {
          background: linear-gradient(0deg, #5ff2fe 0%, #0679e5 100%);
        }
        &:last-child {
          background: linear-gradient(0deg, #9b5ffe 0%, #f6bc61 100%);
        }
      }
    }
  }
  </style>